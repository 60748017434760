.select {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 300px;
    gap: 1px;
}

.select__item {
    padding: 10px;
    cursor: pointer;
    font-family: "Heebo", sans-serif;
    text-align: center;
    border-radius: 3px;
    background: #eeeeee;
    transition: background 0.1s;
}

.select__item--selected {
    background: #009578;
    color: #ffffff;
}

.loading {
    margin: auto;
    margin-top: 20%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}