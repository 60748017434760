.box {

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.box:hover {
    background-color: lightblue;
}