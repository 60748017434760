.main-wrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* --tw-bg-opacity: 1 !important; */
    /* background-color: rgba(46, 0, 75, var(--tw-bg-opacity)) !important; */
    gap:3rem;
    background-color: #212121;

}

/* *{
    font-family: "Inter var","Gaegu" ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
} */

.two-block-wrapper {
    display: flex;
    width: 95%;
    gap:3rem;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem;

}

.leading-tight {
    line-height: 1.25 !important;
}

.right-block-wrapper {
    
    width:25%;
    position: sticky;
    top: calc(100vh - 35.5rem);
}

.right-block-wrapper-content
{
    width:100%;
    height: fit-content;
    padding: 1rem !important;
    /* --tw-bg-opacity: 1 !important; */
    /* background-color: rgba(60, 24, 99, var(--tw-bg-opacity)) !important; */
    background-color: #212121;
    -webkit-box-shadow: -1px 10px 34px 0px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 10px 34px 0px rgba(0,0,0,0.75);
box-shadow: -1px 10px 34px 0px rgba(0,0,0,0.75);
    border-radius: 1.5rem !important;
}

.left-block-wrapper {
    width:75%;
    padding-bottom:1rem;
}

.text-7xl {
    font-size: 4.5rem !important;
    line-height: 1 !important;
    margin-bottom: 1px;
}

.text-white {
    --tw-text-opacity: 1 !important;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.leading-tight {
    line-height: 1.25 !important;
}

.text-orange-500 {
    --tw-text-opacity: 1 !important;
    color: rgba(249, 115, 22, var(--tw-text-opacity)) !important;
}

.gap-x-3 {
    column-gap: 0.75rem !important;
}

.text-yellow-500 {
    --tw-text-opacity: 1 !important;
    color: rgba(245, 158, 11, var(--tw-text-opacity)) !important;
}

.text-red-500 {
    --tw-text-opacity: 1 !important;
    color: rgba(245, 0, 0, var(--tw-text-opacity)) !important;
    margin-top: 0;
}

.text-2xl {
    font-size: 1.5rem !important;
    /* line-height: 1rem !important; */
}

.font-lilita {
    font-family: Heebo !important;
    letter-spacing: .025em;

}

.text-orange-400 {
    --tw-text-opacity: 1 !important;
    color: rgba(251, 146, 60, var(--tw-text-opacity)) !important;
    font-family: 'Heebo';

}

/* .font-bold {
    font-weight: 700 !important;
} */

.w-5 {
    width: 1.25rem !important;
}

.text-orange-500 {
    --tw-text-opacity: 1 !important;
    color: rgba(249, 115, 22, var(--tw-text-opacity)) !important;
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.p-4 {
    padding: 1rem !important;
}

.bg-orange-500 {
    --tw-bg-opacity: 1 !important;
    background-color: rgba(249, 115, 22, var(--tw-bg-opacity)) !important;
}

.border-orange-500 {
    --tw-border-opacity: 1 !important;
    border-color: rgba(249, 115, 22, var(--tw-border-opacity)) !important;
}

.border-2 {
    border-width: 2px !important;
}

.rounded-xl {
    border-radius: 0.75rem !important;
}

.mb-3 {
    margin-bottom: 0.9rem !important;
}

.rounded-3xl {
    border-radius: 1.5rem !important;
}

.flex {
    display: flex !important;
}

.gap-x-3 {
    column-gap: 0.9rem !important;
}

.items-start {
    align-items: flex-start !important;
}

.text-sm {
    font-size: .875rem !important;
    line-height: 1rem !important;
}


img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    vertical-align: middle;
    display: block;
}

.searchNft {
    position: relative;
    /* margin-top: 5px; */
}

.search-icon {
    position: absolute;
    margin-left: 14% !important;
    color: #fff;
}

.navbar .MuiTabs-indicator {
    /* width: 88px!important;
    height: 47px!important;
    border-radius: 44%; */
    border-radius: 25px;
    padding: 23px 0 !important;
}

.navbar .MuiTabs-flexContainer .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #fff !important;
    z-index: 9;
}

.navbar .MuiTabs-flexContainer .MuiButtonBase-root {
    /* width: 88px!important;
    height: 47px!important;
    border-radius: 44%; */
    border-radius: 25px !important;
    padding: 10px 18px;
    background: #79b8fb3d;
    color: #fff;
    margin-right: 10px;
}

.navbar .MuiTabs-flexContainer .MuiButtonBase-root:hover {
    --tw-text-opacity: 1 !important;
    /* color: rgba(59,130,246,var(--tw-text-opacity)) !important; */
}

.nft-wrapper-block {
    display: flex;
    justify-content: space-between;
}

/* .NFT-tab .MuiTabs-flexContainer .MuiButtonBase-root:hover{
    color:#fff!important
} */

.nft-wrapper-block .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    color: #fff;
}

.nft-wrapper-block .css-Isumixr-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #fff !important;
}

.nft-wrapper-block .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    color: #fff;
    z-index: 1;
}

.nft-wrapper-block .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    border: 1px solid #fff !important;
    border-radius: 29px !important;
}

.nft-wrapper-block .MuiInputBase-input {
    color: #fff
}

.css-ptiqhd-MuiSvgIcon-root {
    color: #fff
}

.nft-wrapper-heading {
    color: #fff
}

.btn-right-space {
    margin-left: 10px !important
}

.nft-muliselect-box {
    border: 2px dashed #673ab7;
    /* border-radius: 5%; */
    border-radius: 25px;
    padding: 10px 18px;
    margin-top: 10px;
    /* padding: 10px; */
    background-color: rgba(60, 24, 99, var(--tw-bg-opacity)) !important;

}

.nft-image-hover img {
    width: 130px;
    border-radius: 15px;
    z-index: 2;
    margin-left: -4px;
    margin-top: -5px;

}

.nft-image-hover {
    position: relative;
    margin: 0;
    width: 122px;
    padding: 0;
    margin-left: 13px;
    border: 8px solid #ffffff00;
    height: 121px;
}

.nft-image-hover:hover {
    border: 8px solid #ff7000;
    opacity: 1;
    border-radius: 8px;

}

.nft-image-hover:hover .nft-overlay {
    opacity: 1;
}

.nft-overlay {
    position: absolute;
    /* top: 0; */
    bottom: 0;
    left: -5px;
    right: 0;
    height: 20%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #673ab7;
    padding: 5px;
    margin-bottom: -7px;
    z-index: 0;
    border-radius: 8px;
}

.nft-image-hover-text {
    color: white;
    font-size: -29px;
    position: absolute;
    top: 50%;
    transform: translate(1%, -50%);
    text-align: center;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
}

.nft-Checkbox {
    color: #fff !important;
}

.nft-muliselect-box p {
    color: #fff;

}

.checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    position: relative;
    margin-left: 4px;
    margin-top: 15px;
    bottom: 135px;
}

.nft-wrapper-block Button {
    padding: 7px 20px;
    border-radius: 23px;
}

.checkbox-round:checked {
    background-color: #1976d2;
}

.right_block_btn Button {
    margin-bottom: 10px !important;
    color: rgba(169, 57, 221) !important;
    border: 2px solid rgba(60, 24, 99, var(--tw-bg-opacity)) !important;
    line-height: 2 !important;
}


.tabs-wrapper {
    margin-left: 20px;
}

.selected-count {
    float: right;
    color: #fff;
}

.selecte-box {
    display: flex;
    justify-content: space-between;
}

.walletInput {
    width: 100% !important;
    background: #5d0b86e0;
    border-radius: 8px;
    border: 2px solid #9e26dcfa !important;
    margin-bottom: 20px !important;

}

.walletInput .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    color: rgb(169 169 169 / 60%) !important;
}

.nft-confirm-btn {
    background-color: #ff7000 !important;
    width: 480px;
    border-radius: 23px !important;
    line-height: 2 !important;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(82, 39, 5) 0px 0px 0px 3px inset !important;
}

.nft-reset-btn {
    border: 2px solid rgba(60, 24, 99) !important;
    color: rgba(169, 57, 221) !important;
    margin-right: 10px !important;
    border-radius: 23px !important;
    line-height: 2 !important;

}

.nft-confirm-btn,
.nft-reset-btn,
.right_block_btn Button {
    opacity: 0.9 !important;

}

.nft-confirm-btn:hover,
.nft-reset-btn:hover,
.right_block_btn Button:hover {
    opacity: 1 !important;
    color: #fff !important;
}

/* .nft-modal-div .MuiButtonBase-root {
        color: rgba(169, 57, 221,var(--tw-bg-opacity))!important;
        margin-bottom: 10px;
    } */

.nft-modal .css-1wnsr1i {
    bottom: 0 !important;
    top: 70% !important;
    height: 100px;
    width: 600px !important;
    border: none !important;
    background-color: #240234e0 !important;

}

.no-nft-modal .css-1wnsr1i {
    background-color: #240234e0 !important;
    color: #fff;
    border: none !important;
    text-align: center;
}



@media screen and (min-width: 900px)and (max-width: 1200px) {

    .left-block-wrapper {
        width:45%;
    }

    .two-block-wrapper {
        width: fit-content;
        /* flex-direction: column; */
        /* align-items: center; */
    }

    /* .right-block-wrapper {
        margin-bottom: 10px;
    } */

    .nft-wrapper-block {
        flex-direction: column;
    }

    .right_block_btn {
        margin-top: 10px;
    }

    .right_block_btn .MuiSvgIcon-root {
        right: 125px;
    }
}

@media screen and (max-width: 836px) {
    .nft-modal .css-1wnsr1i {
        width: 400px !important;
    }

    .nft-confirm-btn {
        width: 300px;
    }
}

@media screen and (max-width: 536px) {
    .nft-modal .css-1wnsr1i {
        width: 200px !important;
    }

    .nft-confirm-btn {
        width: 100px;
    }
}

@media screen and (max-width: 1441px) {
    .nft-wrapper-block {
        flex-direction: column;
    }

    .right_block_btn {
        margin-top: 10px;
    }
}

@media screen and (max-width: 970px) {
    .right_block_btn .MuiSvgIcon-root {
        left: 251px;
    }
}



@media screen and (min-width: 601px) and (max-width: 1000px) {
    .marketPlace-link
    {
        width: 40% !important;
    }

    .two-block-wrapper
    {
        flex-direction: column ;
        margin-top: -7rem !important;
    }
    .wrapper-token
    {
        margin-left: 0;
    }
    .nft-heading
    {
        margin-top: 0;
    }
    .right-block-wrapper
    {
        margin-bottom: 2rem;
    }
    .right-block-wrapper,.left-block-wrapper
    {
        width: 96% !important;
        position: relative !important;
    }
    .right-block-wrapper-content
    {
        width: 100%;
    }
    .nft-btns .main-tab-flex:nth-child(2)
    {
        flex-direction: column;
        gap:1rem;
    }
    .two-block-wrapper
    {
        width: 91%;
    }
    .nft-btns
    {
        margin-top: 1rem;
        align-items: center;
    }
    .right-wrapper-content-web,.purple-mobile
    {
        display: none;
    }
    .right-wrapper-content-mobile
    {
        display: block;
    }

    .learnMore-Btn
    {
        display: block !important;
        font-size:18px;
        color:red;
        border: 2px solid red;
        background-color: transparent;
        padding: 8px 10px;
        border-radius: 8px;
        margin-bottom: 1rem;
    }
    .image-border img
    {
        width: 120px !important;
    }
    .image-border {
        width: fit-content !important;
    }
    .image-wrapper
    {
        grid-template-columns: 1fr 1fr 1fr 1fr  !important;
    }
    .sendBtn-container,.nft-heading
    {
        margin-top: 0.95rem;
    }
    .sendBtn-container
    {
        display: flex;
        gap: 0.75rem;
    }
    .perpule-btn-div
    {
        margin-top: -1rem;
    }
    .main-tab-flex .bg-red-500
    {
        margin-right: 0 !important;
    }

}

.nft-modal-div
{
    padding: 1.5rem 1rem;
    border-radius: 16px;
    width: 100%;
}

@media screen and  (max-width: 600px) {
    .two-block-wrapper
    {
        flex-direction: column ;
        margin-top: -7rem !important;
    }
    .right-block-wrapper
    {
        width:100% !important;
        padding: 2rem 1rem;
        position: relative !important;
        top: calc(100vh - 33.5rem);

    }
    .token-sendBtn-container
    {
        display:flex;
        align-items: center;
        gap: 1rem;
        justify-content: center;
    }

    .search-box-main-wrapper
    {
        padding: 1rem 0.45rem;
    }

    .main-tab-flex .bg-red-500
    {
        margin-right: 0;
    }

    .search-box-flex
    {
        justify-content: flex-start !important;
    }

    .search-box-nft
    {
        flex-direction: column;
        gap: 0.25rem;
    }

    .selected-count
    {
        position: relative;
        left: 2rem;
    }
    .search-collection
    {
        width: initial !important;
    }




    .search-container
    {
        width:100% !important;
        gap:0 !important;
    }
    .left-block-wrapper
    {
        width:100%;
    }
    .right-block-wrapper-content
    {
        width:initial !important;
    }
    .nft-btns
    {
        margin-top: 1rem;
    }
    .two-block-wrapper
    {
        width: 90%;
        padding: 0 1rem;
        align-items: center;
        justify-content: center;
    }
    .main-tab-flex
    {
        align-items: center;
        margin: 0;
        justify-content: center;
    }
    .wrapper-content-input
    {
        width: 70px !important;
    }

    .flex-column
    {
        gap: 1rem;
    }
    .nft-heading
    {
        margin-bottom: 0;
        /* text-align: center; */
    }
    .sendBtn-container
    {
        display: flex;
        align-items: center;
        justify-content: center;
        gap:1rem;
    }
    .afterTabDiv,.main-tab-flex,.search-box-main-wrapper,.wrapper-token
    {
        margin-left: 0 !important;
    }
    .main-tab-flex .bg-red-500
    {
        font-size: 1rem !important;
        align-items: center !important;
        justify-content: center !important;
        margin-right: 0px !important;
    }
    .nft-btns .tokenBtn
    {
        margin-top: 0 !important;
    }
    .nft-btns
    {
        height: fit-content !important;
    }
    .not-selected
    {
        width: fit-content;
    }
    .modal-style1,.modal-style
    {
        width:85% !important;
        transform: none !important;
        left:auto !important;
        text-align: center;
        margin: 0 !important;

    }
    .modal-style1 div:nth-child(1),.nft-modal-btn
    {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .not-selected
    {
        padding: 0 !important;
        margin: 15px 0 !important;
    }
    .scroll2
    {
        height: 23rem !important;
    }
   
    .btnOrange
    {
        height: fit-content !important;
    }
    .connect-wallet-div
    {
        margin-top: 6rem !important;
    }
    .page-heading
    {
        text-align: center;
        width: 100%;
        right: 0 !important;
        /* right:5% !important; */
        font-size: 3.3rem !important;
    }
    .icon
    {
        left: 10px !important;
    }
    .menushow {
        right:5%;
        top: 1rem;
    }
    .learnMore-Btn
    {
        display: block;
        font-size:18px;
        color:red;
        border: 2px solid red;
        background-color: transparent;
        padding: 8px 10px;
        border-radius: 8px;
        margin-bottom: 1rem;
    }
    .right-wrapper-content-web,.purple-web
    {
        display: none;
    }
    .right-wrapper-content-mobile,.purple-mobile
    {
        display: block;
    }
    .main-wrapper
    {
        gap:0;
    }
    .image-border img
    {
        width: 120px !important;
    }
    .image-border {
        width: fit-content !important;
    }
    
}

@media screen and (max-width:400px)
{
    .image-wrapper
    {
        grid-template-columns: 1fr 1fr !important;
        gap: 0.25rem !important;
        justify-items: center !important;

    }
    .search-box-main-wrapper
    {
        padding: 1rem 0.5rem;
    }
}

@media screen and (min-width:401px) and (max-width:600px)
{
    .image-wrapper
    {
        grid-template-columns: 1fr 1fr  !important;
        justify-items: center;

    }
}


@media screen and (min-width:1001px){
.learnMore-Btn,.right-wrapper-content-mobile,.purple-mobile
{
    display: none;
}    
.right-wrapper-content-web,.purple-web
{
    display: block;
}

}

@media screen and (min-width:1001px) and (max-width:1200px){
.nft-image-overlay-hover-text
{
    width: 97% !important;
}
}


@media screen and (min-width:1450px){
    .nft-image-overlay-hover-text
    {
        width:99% !important;
    }
    }

    .marketPlace-link
    {
        margin:0.5rem 0;
        border: 1.5px dashed red;
        width: 100%;
        padding: 1px 0 1px 5px;
        border-radius: 8px;
        margin: 1.5rem 0;
        color: white;
        font-size: 0.875rem;
        font-family: 'Heebo', sans-serif;
        text-align:center;
    }

.marketPlace-link>p>a
{
    text-decoration:underline;
    color: white;

}

.marketPlace-link>p>a:hover,
.redText-highlight
{
    color:  rgb(245, 0, 0);
}

.goldText-highlight
{
    color: gold;
}

.leading-title
{
    font-size: 1.5rem !important;
    color:  rgb(245, 0, 0);
    font-weight: 600;
}