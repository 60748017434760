.loader {
    width:25px;
    height:25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid white;
    border-top-color: rgb(42, 3, 83);
    animation-name: box;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: 0s;
}


@keyframes box {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}