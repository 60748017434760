.App {
  text-align: center;
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.checkcontainer {
  position: relative;
  text-align: center;
  color: white;
  width: 100px;
  height: 100px;
}

.checkcentered {
  position: absolute;
  top: 25%;
  /* left: 80%; */
  right: 10%;
  /* width: 30px; */
  /* transform: translate(-50%, -50%); */
}

.nfttransfer {
  /* opacity: 0.8; */
  position: absolute;
  bottom: 1%;
  right: 30%;
  width: 60%;
  height: 120px;
}


.scroll {
  margin: auto;
  padding: auto;
  height: 60%;
  width: 95%;
  overflow: hidden;
  position: absolute;
  border: #61dafb;
  border-style: solid;
  margin-left: 2%;


}

.iframeContainer {
  position: relative;
  width: 100%;
  height: 77%;
  overflow: hidden;
  margin: auto;
  overflow-y: scroll
}

.iframecontent {
  /* position: relative;
  margin: auto; */
  /* top: -500px;
  left: -270px;
  width: 80%;
  height: 1000px; */
  /* pointer-events: none; */
}

.transferloading {
  position: absolute;
  top: 5%;
  left: 5%;
  /* transform: translate(-50%, -50%); */
}

.headingleft {
  position: absolute;

  left: 3%;
}

.tokenscroll {
  margin: auto;
  padding: auto;
  height: 40%;
  width: 95%;
  overflow: hidden;
  position: absolute;
  border: #61dafb;
  border-style: solid;
  margin-left: 2%;


}

.tokeniframeContainer {
  position: relative;
  width: 100%;
  height: 60%;
  overflow: hidden;
  margin: auto;
  overflow-y: scroll
}

.tokentransfer {
  /* opacity: 0.8; */
  position: absolute;
  bottom: 1%;
  right: 30%;
  width: 60%;
  height: 200px;
}