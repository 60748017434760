.box1,
.box2,
.box3,
.box4,
.box5,
.box6,
.box7,
.box8,
.box9,
.box10 {
    background-color: #ffffff;
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    /* height: 200px; */
    max-height: 35vh;
    -webkit-box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
}

.box {
    margin: 20px;
}

.grid {
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.grid>div {
    flex-basis: calc(100px);
}

.boxy {
    width: 100px;
    background: green;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}

.boxy:hover {
    animation: boxyRotation 4s infinite linear;
}

@keyframes boxyRotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.black-background {
    background-color: red !important;
    font-size: 5vh;
    border-radius: 10px 10px 10px 10px;
}