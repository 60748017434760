/* @import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap'); */

.menuiconlabel,
.menuiconDiv {
  display: none;
}

.connect-wallet-div {
  position: fixed;
  right: 1rem;
  top: 0;
  margin-top: 20px;
  display: flex;
  /* background-color: rgba(251, 146, 60, var(--tw-bg-opacity)) !important; */
  border-radius: 8px;
  padding: 0.75rem;
  align-items: center;
  cursor: pointer;
  z-index: 10;
}

.connect-wallet-div:hover {
  color: rgba(255, 255, 255);
  background-color: red;
}

.connect-wallet-id-btn {
  font-size: 1.125rem;
  line-height: 1.75rem;
  background: transparent;
  border: 0px solid rgb(229, 231, 235);
  width: 8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Heebo', sans-serif;
  cursor: pointer;
}

.connect-wallet-btn {
  font-size: 1.125rem;
  line-height: 1.75rem;
  background: transparent;
  border: 0px solid rgb(229, 231, 235);
  /* width: 7rem; */
  color: #fff;
  text-transform: uppercase;
  font-family: 'Heebo', sans-serif;
  cursor: pointer;
}

/* .connect-wallet-btn,
.connect-wallet-btn-active {
  width: 11rem;
} */

.wallet-adapter-button-start-icon {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
}

.wallet-adapter-button-start-icon img {
  width: 100%;
}

.two-block-wrapper {
  font-family: 'Heebo', sans-serif;
  margin-top: -1rem;
  z-index: 9;
}

.main-content {
  display: contents;
}

.sub-menu {
  display: none;
}

.sub-menu-active {
  display: block;
  position: absolute;
  right: 27px;
  top: 90px;
  width: 7rem;
}

.wallet-adapter-dropdown-list {
  width: 9rem;
  z-index: 9;
  row-gap: 10px;
  color: #fff;
  background: rgb(44, 45, 48);
  border-radius: 10px;
  grid-template-rows: 1fr;
  margin: 0px;
  padding: 10px 0;
  font-family: 'Heebo', sans-serif;
  list-style: none;
  transition: opacity 0.2s ease 0s, -webkit-transform 0.2s ease 0s,
    -webkit-transform 0.2s ease 0s, transform 0.2s ease 0s,
    visibility 0.2s ease 0s;
  display: grid;
  position: absolute;
  top: -1rem;
  right: 0px;
  box-shadow: rgb(0 0 0 / 60%) 0px 8px 20px;
  text-align: center;
  cursor: pointer;
}

.wallet-adapter-dropdown-list li {
  padding: 0.625rem 0px;
  width: 100%;
  /* line-height: 1rem; */
}

.wallet-adapter-dropdown-list li:hover {
  background: rgb(54, 54, 54);
  font-weight: 600;
}
.main-tab-flex {
  display: flex;
  margin-left: 20px;
  gap: 0.5rem;
}

.connect-active,
.confirm-loader-btn-active,
.confirm-btn-active,
.token-block-active,
.nft-block-active {
  display: block;
}

.connect-hide,
.connect-wallet-id-btn-hide,
.confirm-loader-btn-hide,
.token-block-hide,
.nft-block-hide,
.hide-amount-send-block {
  display: none;
}

.connect-wallet-btn-hide,
.disconnect-hide,
.confirm-btn-hide {
  display: none;
}

.loading-nfts-hide {
  display: none;
}

.load-token-hide {
  display: none;
}

.own-nfts {
  display: none;
}

.own-tokens {
  display: none;
}

.load-token-active {
  display: block;
  text-align: center;
  margin-top: 12%;
  color: #fff;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.own-zero-tokens {
  display: block;
  text-align: center;
  margin-top: 12%;
  color: #fff;
  font-size: 1.25rem;
}

.own-zero-nfts {
  display: block;
  text-align: center;
  margin-top: 12%;
  color: #fff;
  font-size: 1.25rem;
}

.loading-nfts-active {
  display: block;
  text-align: center;
  margin-top: 12%;
  color: #fff;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.loading-nfts-page {
  display: block;
  text-align: center;
  /* margin-top: 12%; */
  color: #fff;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.connect-wallet-id-btn-active,
.connect-wallet-btn-active {
  display: flex;
  align-items: center;
}

.disconnect-active {
  display: block;
  text-align: center;
  margin-top: 12%;
  color: #fff;
  font-size: 1.25rem;
}

.not-selected {
  color: white;
  background: rgb(39 17 17 / 80%) !important;
  width: 300px;
  height: 50px;
  /* padding-top: 20px; */
  /* padding-left: 120px; */
  align-items: center;
  position: relative;
  align-self: center;
  margin: 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-heading {
  font-size: 4.3rem;
  line-height: 1;
  color: rgba(255, 255, 255);
  font-family: 'Heebo';
  letter-spacing: 0.025em;
  position: absolute;
  bottom: 0;
  right: 37%;
}

.top-banner {
  /* background-image: url(); */
  /* background-image: linear-gradient(rgba(46, 0, 75, 0.498), rgb(46, 0, 75)), url(https://famousfoxes.com/BeardBanner.4e99a03d.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.main-tab-flex .bg-blue-500 {
  line-height: 3rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  padding: 0 17px;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  border-radius: 9999px;
  text-transform: none;
  letter-spacing: 0.025em;
  font-size: 1.26rem;
  font-family: 'Heebo', sans-serif;
  border: none;
  margin-right: 20px;
  cursor: pointer;
}

.main-tab-flex .bg-red-500 {
  line-height: 3rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  padding: 0 17px;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  border-radius: 8px;
  text-transform: none;
  letter-spacing: 0.025em;
  font-size: 24px;
  font-family: 'Heebo', sans-serif;
  border: none;
  margin-right: 20px;
  cursor: pointer;
}

.perpule-btn-div button {
  font-weight: 700;
}
.perpule-btn-div button:hover {
  background-color: rgba(139, 92, 246, 0.2);
}

.main-tab-flex .tokenBtn {
  background-color: rgba(110, 114, 121, 0.2);
}
.btnBlueunselected {
  background-color: #3b82f6;
}

.main-tab-flex .tokenBtn:hover {
  --tw-text-opacity: 1;
  color: rgba(120, 120, 120, var(--tw-text-opacity));
}

.afterTabDiv {
  margin-left: 20px;
  --tw-border-opacity: 1;
  /* background: rgba(60, 24, 99, var(--tw-border-opacity)); */
  background-color: red;
  padding: 2px;
  margin-top: 20px;
}

.flex-column {
  flex-direction: column;
}

.nft-btns {
  display: flex;
  justify-content: space-between;
  height: 8rem;
}

.nft-heading {
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.perpule-btn-div {
  display: flex;
  justify-content: end;
}

.search-collection {
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  border-width: 2px;
  border-radius: 9999px;
  --tw-text-opacity: 1;
  color: #fff;
  /* color: rgba(177, 108, 221); */
  padding-right: 45px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  width: 100%;
  font-family: 'Heebo';
}

.search-collection:hover {
  --tw-border-opacity: 1 !important;
  border-color: red !important;
}

.search-collection::placeholder {
  font: 20px/3 'Heebo', sans-serif;
  line-height: 2rem;
  color: #fff;
}

.search-icon {
  position: absolute !important;
  right: 21px;
  top: 14px;
}

.search-icon:hover {
  color: rgba(177, 108, 221) !important;
}

.search-box-nft {
  position: relative;
}

.bg-perpule-500 {
  background-color: rgba(110, 114, 121, 0.2) !important;
  --tw-text-opacity: 1;
  color: white;
  /* color: rgba(167, 139, 250, var(--tw-text-opacity)); */
  padding-left: 1rem;
  padding-right: 1rem;
  padding: 0.8rem;
  --tw-border-opacity: 1;
  /* border-color: rgba(139, 92, 246, var(--tw-border-opacity)); */
  border-color: rgba(110, 114, 121, 0.2);
  border-width: 2px;
  border-radius: 8px;
  /* font-family: inherit; */
  font-family: 'Heebo';
  letter-spacing: 0.025em;
  font-size: 1rem;
  margin: 0.5rem 0.2rem;
  cursor: pointer;
}

.bg-perpule-500:hover {
  --tw-text-opacity: 1;
  color: rgba(120, 120, 120, var(--tw-text-opacity));
}

.search-box-main-wrapper {
  background-color: rgba(60, 24, 99, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: red;
  border-style: dashed;
  border-width: 2px;
  border-radius: 1.5rem;
  margin-top: 2rem;
  padding: 1rem;
  margin-left: 1.5rem;
}

.search-box-nft {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.search-box-flex {
  display: flex;
  justify-content: space-between;
}

.rounded-lg {
  position: relative !important;
}

.rounded-lg .checkmark {
  border-radius: 0.5rem !important;
}

.checkbox-all-nft {
  color: #fff;
  margin-left: 2rem;
}

.selected-count {
  --tw-text-opacity: 1 !important;
  color: red !important;
}

.image-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
  width: 100%;
  
  position: relative;
  z-index: 1;
}

.image-border {
  /* width: 15%; */
  border: 4px solid #1976d200;
  position: relative;
  cursor: pointer;
  height: 122px;
  width: 122px;
  border-radius: 0.75rem;
}

.image-border img {
    height: 122px;
    width: 122px;
    object-fit: cover;
    border-radius: 0.5rem;


}

.image-not-selected:hover {
  border: 4px solid #1976d2;
  border-radius: 0.75rem;
}

.image-border:hover {
  /* .image-selected:hover{ */
  border: 4px solid rgba(249, 115, 22);
  border-radius: 0.75rem;
  z-index: 1;
}

.nft-image-overlay-hover-text {
  display: none;
}

.nft-image-overlay-hover-text {
  position: absolute;
  display: none;
  padding: 2px;
  color: #fff;
  font-size: 10px;
  bottom: -55px;
  /* background-color: #673ab7; */
  background-color: red;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  text-align: center;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  /* overflow: hidden; */
  transition-property: all !important;
  transition-duration: 0.5s !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  width: 97%;
}

.image-border .container {
  /* display: none; */
  display: block;
}

.image-border:hover .container {
  display: block;
}

.image-border:hover .nft-image-overlay-hover-text {
  display: block;
  bottom: 0px;
}

.image-overlay {
  width: 100%;
}

.image-border .container {
  top: 8px;
  left: 10px;
}

.search-container {
  width: 36%;
}

.container {
  display: block;
  position: absolute;
  /* padding-left: 35px; */
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  background-color: #fff;
  border-radius: 15px;
}

.container:hover input ~ .checkmark {
  background-color: #fff;
}

.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 2px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nft-image-hover:hover .nft-overlay {
  opacity: 1;
}

.toast-message {
  background: #000 !important;
  color: #fff !important;
}

.Toastify__close-button {
  color: #fff !important;
}

/* .nft-open-modal{
    width: 30rem;
} */
.no-nft-modal {
  color: #fff !important;
}

.nft-modal-div .nft-modal-input {
  --tw-text-opacity: 1;
  width: 94%;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding: 0.5rem;
  --tw-bg-opacity: 1;
  /* background-color: rgba(60, 24, 99, var(--tw-bg-opacity)); */
  background-color: black;
  --tw-border-opacity: 1;
  border-color: red;
  border-width: 2px;
  border-radius: 0.5rem;
}

.nft-modal-input-textArea {
  --tw-text-opacity: 1;
  width: 94%;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding: 0.5rem;
  --tw-bg-opacity: 1;
  /* background-color: rgba(60, 24, 99, var(--tw-bg-opacity)); */
  background-color: black;
  --tw-border-opacity: 1;
  border-color: red;
  border-width: 2px;
  border-radius: 0.5rem;
  max-height: 10rem;
  max-width: 94%;
  min-height: 4rem;
  min-width: 94%;
}

input:focus,
textarea:focus {
  outline: none !important;
  border-color: red;
}

.modal-reset-btn {
  --tw-text-opacity: 1;
  color: red;
  font-weight: 700;
  font-size: 1rem;
  margin-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding: 1rem;
  --tw-border-opacity: 1;
  background-color: black !important;
  /* border-color: rgba(139, 92, 246, var(--tw-border-opacity)); */
  border-color: red;
  border-width: 2px;
  border-radius: 8px;
  /* width: 15%; */
  cursor: pointer;
}

.modal-confirm-btn {
  display: flex;
  letter-spacing: 0.025em;
  --tw-shadow: 0 4px 0 0 #56290f;
  --tw-shadow-colored: 0 4px 0 0 var(--tw-shadow-color);
  /* box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow); */
  opacity: 0.9;
  --tw-text-opacity: 1;
  color: red;
  line-height: 1;
  font-size: 1.125rem;
  font-family: 'Heebo';
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding: 1rem;
  --tw-bg-opacity: 1;
  background-color: black !important;
  --tw-border-opacity: 1;
  border-color: red;
  border-width: 4px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-top: 0.5rem;
  cursor: pointer;
}

.modal-confirm-btn:active {
  transform: translateY(6px);
}

.nft-modal-btn {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.animate-spin {
  animation: spin 0.8s linear infinite;
  border: 0.2rem solid #aeadad6d;
  border-radius: 50%;
  border-top: 0.2rem solid #eeeeee;
  width: 0.6rem;
  height: 0.6rem;
}

.wrapper-content-text-center p {
  margin: 0;
  text-align: center;
}

.wrapper-token {
  padding: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: #ff00008c;
  --tw-border-opacity: 1;
  border: 4px solid transparent;
  border-radius: 0.75rem;
  /* border-color: rgba(249,115,22,var(--tw-border-opacity)) ; */
  cursor: pointer;
  overflow: hidden;
  column-gap: 0.75rem;
  display: flex;
  position: relative;
  color: #fff;
  margin-left: 1.25rem;
  margin-top: 1rem;
}

.token-text-xs {
  margin: 0;
  font-size: 0.75rem;
  line-height: 1rem;
  font-family: 'Heebo';
}

.token-text-lg {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-family: 'Heebo';
}

.wrapper-token img {
  width: 60px;
  height: 60px;
  border-radius: 0.5rem;
}

.wrapper-token-flex-1 {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  color: #fff;
}

.max-btn {
  position: absolute;
  --tw-text-opacity: 1;
  color: rgba(249, 115, 22, var(--tw-text-opacity));
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: block;
  right: 0.75rem;
  top: 0.875rem;
  cursor: pointer;
  text-transform: none;
  background: transparent;
  border: none;
}

.wrapper-content-input {
  position: relative;
  --tw-text-opacity: 1;
  font-family: 'Heebo', sans-serif;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding-right: 1.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(39 17 17 / 80%);
  --tw-border-opacity: 1;
  border: 2px solid red;
  border-radius: 0.5rem;
  width: 108px;
}

.wrapper-content-input-div {
  position: relative;
}

.wrapper-content-input-div input::-webkit-outer-spin-button,
.wrapper-content-input-div input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.wrapper-content-input-div input[type='number'] {
  -moz-appearance: textfield;
}

.activeTokenBtn {
  border-width: 4px;
  border: 4px solid rgba(249, 115, 22);
  border-radius: 0.75rem;
}

.wrapper-token {
  font-family: 'Inter', sans-serif;
}

.wrapper-token:hover {
  border: 4px solid rgba(59, 130, 246);
  border-width: 4px;
  border-radius: 0.75rem;
}

.modal-style {
  position: fixed;
  
  z-index: 2;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 30%;
  margin: 7px;
  padding: 10px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}
.nft-modal-div {
  background: rgb(39 17 17 / 80%) !important;
}

.modal-style1 {
  position: fixed;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  
  z-index: 2;
  /* width: 600px; */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.leading-tight {
  font-size: 18px;
}



/* .icon {
  width: 60px;
  height: 60px;
  margin-top: 20px;
  top: 0;
  position: fixed;
  left: 25px;
  z-index: 10;
  padding: 4px;
  border-radius: 50%;
  background-color: black;
  
} */

.scroll2 {
  overflow-y: scroll;
  /* height: 24rem; */
  scrollbar-width: 0px;
  overflow-x: hidden;
}

.scroll2::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .left-block-wrapper {
    width: 80%;
  }

  /* .right-block-wrapper {
        width: 300px;
        height: 300px;
    } */

  .two-block-wrapper {
    /* width: 98%; */
    /* flex-direction: column; */
    /* align-items: center; */
  }
  /* 
    .right-block-wrapper {
        margin-bottom: 10px;
    } */

  .nft-wrapper-block {
    flex-direction: column;
  }

  .right_block_btn {
    margin-top: 10px;
  }

  .right_block_btn .MuiSvgIcon-root {
    right: 125px;
  }
}

@media screen and (max-width: 855px) {
  /* .nft-btns .tokenBtn {
        margin-top: 1rem;
    } */

  .nft-btns .main-tab-flex:nth-child(2) {
    flex-direction: column-reverse;
    /* margin-top: 1rem; */
  }

  .image-border {
    width: 31%;
  }

  .css-1r7wll8 {
    width: 30rem !important;
  }

  .nft-modal-div .nft-modal-input {
    /* --tw-text-opacity: 1; */
    width: 94%;
  }

  /* .modal-reset-btn {
        width: 22%;

    } */
}

@media screen and (max-width: 639px) {
  .nft-btns {
    justify-content: space-between;
    flex-direction: column;
  }

  .perpule-btn-div {
    display: flex;
    justify-content: start;
  }

  .nft-btns .search-box-nft {
    margin-top: 1rem;
    margin-bottom: 0.2rem;
    width: 100%;
  }

  /* .search-icon {
        left: 8rem;
    } */

  .css-1r7wll8 {
    width: 20rem !important;
  }

  /* .modal-reset-btn {
        width: 100%;
    } */

  .modal-confirm-btn {
    width: 100%;
  }

  .menuiconDiv {
    display: block;
    background-color: red;
    border-radius: 9999px;
    position: fixed;
    right: 4%;
    top: 4%;
    line-height: 60px;
    vertical-align: middle;
    width: 4rem;
    height: 4rem;
    text-align: center;
    cursor: pointer;
    z-index: 10;
  }

  .menuiconlabel {
    background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2rem;
    font-family: 'OpenSans-Bold';
    display: block;
  }

  .menuhide {
    display: none;
  }
}

@media screen and (max-width: 520px) {
  .image-border {
    width: 47%;
  }

  /* .wrapper-token {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    } */
}

@media screen and (max-width: 460px) {
  .image-border {
    width: 98%;
  }
}
